import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

export const StyledPanelImage = styled.img`
    object-fit: cover;
    padding: 0;
`;

export const StyledBackgroundImage = styled.div`
    display: inline-block;
    background-image: url(${props => props.image.childImageSharp ? props.image.childImageSharp.fluid.src : props.image});
    background-position: center;
    height: auto;
`;

export const PanelImage = props => (
    <StyledPanelImage
        {...props}
        src={
            !!props.image.childImageSharp
                ? props.image.childImageSharp.fluid.src
                : props.image
        }
    />
);

export const BackgroundPanelImage = props => (
    <StyledBackgroundImage
        {...props}
        src={
            !!props.image.childImageSharp
                ? props.image.childImageSharp.fluid.src
                : props.image
        }
    />
);

const CollageColumns = styled.div`
    div {
        padding-left: 0em;
        padding-right: 0em;
    }
    div:not(:first-child) {
        padding-left: 0.5em;
    }
    div:not(:last-child) {
        padding-right: 0.5em;
    }
`;

export const Collage = ({ collage }) => (
    <React.Fragment>
        <CollageColumns className="columns">
            {collage.slice(0, 4).map(image => (
                <div className="column is-3">
                    <Img
                        fluid={
                            image.image.childImageSharp
                                ? image.image.childImageSharp.fluid
                                : { src: image.image }
                        }
                    />
                </div>
            ))}
        </CollageColumns>
        <CollageColumns className="columns">
            {collage.slice(4, 8).map(image => {
                return (
                    <div className="column is-3">
                        <Img
                            fluid={
                                image.image.childImageSharp
                                    ? image.image.childImageSharp.fluid
                                    : { src: image.image }
                            }
                        />
                    </div>
                );
            })}
        </CollageColumns>
        {collage.length > 8 && (
            <CollageColumns className="columns">
            {collage.slice(8, 12).map(image => {
                return (
                    <div className="column is-3">
                        <Img
                            fluid={
                                image.image.childImageSharp
                                    ? image.image.childImageSharp.fluid
                                    : { src: image.image }
                            }
                        />
                    </div>
                );
            })}
            </CollageColumns>
        )}
        {collage.length > 12 && (
            <CollageColumns className="columns">
            {collage.slice(12, 16).map(image => {
                return (
                    <div className="column is-3">
                        <Img
                            fluid={
                                image.image.childImageSharp
                                    ? image.image.childImageSharp.fluid
                                    : { src: image.image }
                            }
                        />
                    </div>
                );
            })}
            </CollageColumns>
        )}
        {collage.length > 16 && (
            <CollageColumns className="columns">
            {collage.slice(16, 20).map(image => {
                return (
                    <div className="column is-3">
                        <Img
                            fluid={
                                image.image.childImageSharp
                                    ? image.image.childImageSharp.fluid
                                    : { src: image.image }
                            }
                        />
                    </div>
                );
            })}
            </CollageColumns>
        )}
    </React.Fragment>
);
