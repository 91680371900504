import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { HeroContainer, Panel } from '../components/Containers';
import { Collage } from '../components/Image';

export const SlowLivingPageTemplate = ({
    title,
    image,
    subheading,
    blurbs,
    sections,
    collage
}) => (
    <div>
        <HeroContainer title={title} image={image} subheading={subheading} />
        <section className="section section--gradient">
            <div className="container">
                {sections.map((section, index) => (
                    <Panel
                        {...section}
                        textFirst={index % 2 === 0}
                        color={index % 2 !== 0 ? 'green' : 'red'}
                    />
                ))}
                <Collage collage={collage} />
            </div>
        </section>
    </div>
);

SlowLivingPageTemplate.propTypes = {
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    title: PropTypes.string,
    heading: PropTypes.string,
    subheading: PropTypes.string,
    mainpitch: PropTypes.object,
    blurbs: PropTypes.array
};

const SlowLivingPage = ({ data }) => {
    const { frontmatter } = data.markdownRemark;

    return (
        <Layout>
            <SEO
                title={frontmatter.title}
                image={frontmatter.image.childImageSharp.fluid.src}
                description={frontmatter.description}
            />
            <SlowLivingPageTemplate
                title={frontmatter.title}
                heading={frontmatter.heading}
                subheading={frontmatter.subheading}
                image={frontmatter.image}
                blurbs={frontmatter.blurbs}
                sections={frontmatter.sections}
                collage={frontmatter.collage}
            />
        </Layout>
    );
};

SlowLivingPage.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.object
        })
    })
};

export default SlowLivingPage;

export const pageQuery = graphql`
    query SlowLivingPageTemplate {
        markdownRemark(
            frontmatter: { templateKey: { eq: "slow-living-page" } }
        ) {
            frontmatter {
                title
                image {
                    childImageSharp {
                        fluid(maxWidth: 2048, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                heading
                subheading
                sections {
                    title
                    image {
                        childImageSharp {
                            fluid(maxWidth: 2048, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    description
                    about
                    link
                }
                collage {
                    image {
                        childImageSharp {
                            fluid(maxWidth: 2048, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                blurbs {
                    image {
                        childImageSharp {
                            fluid(maxWidth: 2048, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    heading
                    text
                }
            }
        }
    }
`;
